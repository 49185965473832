<template>
  <div class="block">
    <div class="block__main">
      <img class="block__grid" src="@/assets/img/gridOther.svg" />
      <img class="block__grid-mob" src="@/assets/img/gridMob.svg" />
    </div>
    <Guid :isFifa="isFifa" :name="activeGame.name" :rules="activeGame.rules" :desc="activeGame.desc" :whyDesc="activeGame.whyDesk" :prize="activeGame.prize" :link="activeGame.linkTurn" />
    <TurnaTable v-if="false" />
    <Video :videoAll="activeGame.videoAll" />
    <Form :desk="activeGame.whyDesk" />
    <Footer />
  </div>
</template>

<script>
import Guid from '../blocks/Guid';
import TurnaTable from '../blocks/TurnaTable';
import Video from '../blocks/Video';
import Form from '../blocks/Form';
import Footer from '../blocks/Footer';

export default {
  name: 'Warface',
  components:{
    Guid, TurnaTable, Video, Form, Footer
  },
  computed: {
    activeGame () {      
      return this.$store.state.games.find(e => e.route == this.$route.name)
    },
    isFifa(){
      return this.$route.name == 'Fifa'      
    }
    
  },
  mounted(){
    
  }
}

</script>