<template>
  <div class="guid" id="Guid">    
    <div class="guid-center">
      <div class="guid-top">
        <img class="guid-top__number" src="@/assets/img/blocks/01.svg" alt="01">
        <div class="guid-top__txt">
          Информация <br />о турнире
        </div>
      </div>
      <div class="guid-center-main">
        <div class="guid-center__title" v-html="name"></div>
        <a href="#video" class="guid-center__game">
          <img class="guid-center__game__left" src="@/assets/img/glich/item/btn_bel1.gif" />
          <img class="guid-center__game__right" src="@/assets/img/glich/item/btn_bel2.gif" />
          <div class="guid-center__game__txt">
            ГАЙД ПО ИГРЕ
          </div>
        </a>
      </div>
    </div>
    <div class="guid-bot">
      <div class="guid-bot-left">        
        <div class="guid-bot-left-main">
          <div class="guid-bot-left-main__txt" v-html="rules">
          </div>
          <div class="guid-bot-left-main-btns">
            <a v-if="false" class="guid-bot-left-main-btns__item" href="https://discord.gg/2dZmRsDYEA" target="_blank">
              <span>
                Нужна команда!
              </span>
            </a>
            <a class="guid-bot-left-main-btns__item green" :href="link" target="_blank">
              <span>
                Следить за турниром!
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="guid-bot-right">        
        <div class="guid-bot-right__desc">
          <p v-html="desc">
          </p>
          <div @click="visWhyTur = true" class="guid-bot-right__desc-l">Зачем этот турнир? </div>
        </div>

        <div v-if="visWhyTur" class="guid-bot-fix">
        <img src="@/assets/img/guids/close.svg" class="guid-bot-fix__close" @click="visWhyTur = false" />
        <div class="guid-bot-fix__txt" v-html="whyDesc">
          
        </div>
      </div>
      </div>      
    </div>

    <div v-if="false" class="guid-top">
      <img class="guid-top__plus" src="@/assets/img/guids/plus.svg" alt="01">
      <div class="guid-top__txt">
        Индивидуальный рейтинг игроков
      </div>
    </div>

    <div v-if="false" class="guid-center">
      <div class="guid-center__title long" v-html="name + ' OPEN CUP'" ></div>
      <div class="guid-center__season">
        SEASON XV        
      </div>
    </div>

    <div v-if="false" class="guid-table">
      <table>
        <thead>
          <tr>            
            <th data-type="numeric">место<span class="resize-handle"></span></th>
            <th data-type="text-short">игрок<span class="resize-handle"></span></th>
            <th data-type="text-short">страна<span class="resize-handle"></span></th>
            <th data-type="text-short">матчи<span class="resize-handle"></span></th>            
            <th data-type="text-short">победы<span class="resize-handle"></span></th>
            <th data-type="text-short">победы %<span class="resize-handle"></span></th>
            <th data-type="numeric">рейтинг<span class="resize-handle"></span></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="itemTable in tableDate.sort(function(a,b) {return a.position - b.position})">
            <td><img src="@/assets/img/guids/up.svg" /> {{itemTable.position}}</td>
            <td>{{itemTable.name}}</td>
            <td>{{itemTable.country}}</td>
            <td>{{itemTable.matchCount}}</td>
            <td>{{itemTable.win}}</td>
            <td>{{(itemTable.win/itemTable.matchCount).toFixed(1) * 100}}%</td>
            <td>{{itemTable.rank}}</td>
          </tr>        
        </tbody>
      </table>
    </div>

    <div class="guid-top prize">
      <img class="guid-top__plus" src="@/assets/img/guids/plus.svg" alt="01">
      <div class="guid-top__txt">
        Призы
      </div>
    </div>

    <div class="guid-prize">
      <div class="guid-prize-item first">        
        <div class="guid-prize-item__squad a"></div><div class="guid-prize-item__squad b"></div><div class="guid-prize-item__squad c"></div><div class="guid-prize-item__squad d"></div>
        <img class="guid-prize-item__angle right" src="@/assets/img/glich/item/1.gif" />
        <img class="guid-prize-item__angle left" src="@/assets/img/glich/item/2.gif" />
        <img class="guid-prize-item__angle rightBot" src="@/assets/img/glich/item/3.gif" />
        <img class="guid-prize-item__kart" :src="require(`@/assets/img/guids/${isFifa ? 'prize-fifa' : 'prize'}.png`)" />
        <div class="guid-prize-item__nmbr">   
          <img src="@/assets/img/guids/1.svg" />         
          <span> место</span>
        </div>
        <div class="guid-prize-item__pl">
          <div v-for="itemPrize in prize[0]" class="guid-prize-item__pl__el">
            <img src="@/assets/img/guids/doc-b.svg" />
            <span v-html="itemPrize"></span>
          </div>          
        </div>
      </div>
      <div class="guid-prize-item">
        <img class="guid-prize-item__little right" src="@/assets/img/glich/item/1.gif" />
        <img class="guid-prize-item__little left" src="@/assets/img/glich/item/2.gif" />
        <img class="guid-prize-item__little rightBot" src="@/assets/img/glich/item/3.gif" />
        <div class="guid-prize-item__squad a"></div><div class="guid-prize-item__squad b"></div><div class="guid-prize-item__squad c"></div><div class="guid-prize-item__squad d"></div>
        
        <div class="guid-prize-item__nmbr">
          <img class="b" src="@/assets/img/guids/2.svg" /> 
          <img class="g" src="@/assets/img/guids/2-g.svg" /> 
          <span> место</span>
        </div>
        <div class="guid-prize-item__pl">
          <div v-for="itemPrize in prize[1]" class="guid-prize-item__pl__el">
            <img class="b" src="@/assets/img/guids/doc-b.svg" />
            <img class="g" src="@/assets/img/guids/doc-g.svg" />
            <span v-html="itemPrize"></span>
          </div>          
        </div>
      </div>
      <div class="guid-prize-item">
        <div class="guid-prize-item__squad a"></div><div class="guid-prize-item__squad b"></div><div class="guid-prize-item__squad c"></div><div class="guid-prize-item__squad d"></div>
        
        <div class="guid-prize-item__nmbr">
          <img src="@/assets/img/guids/3.svg" /> 
          <span> место</span>
        </div>
        <div class="guid-prize-item__pl">
          <div v-for="itemPrize in prize[2]" class="guid-prize-item__pl__el">
            <img src="@/assets/img/guids/doc-g.svg" />
            <span v-html="itemPrize"></span>
          </div>          
        </div>
      </div>
    </div>

  </div>  
</template>

<script>
import style from './style.scss';

export default {
  name: 'Guid',
  props: ['isFifa','name', 'rules', 'desc', 'whyDesc', 'prize', 'link'],
  data(){
    return {
      tableDate: [
        {id:0, position: 8, name: 'капитан паника', country: 'Казахстан', matchCount: 9, win: 7, rank: 2.9154},
        {id:1, position: 2, name: 'лицо со шрамом', country: 'Украина', matchCount: 9, win: 2, rank: 2.3154},
        {id:2, position: 1, name: 'лицо без шрама', country: 'Беларусъ', matchCount: 9, win: 3, rank: 2.2154},
        {id:3, position: 3, name: 'капитан паника', country: 'Россия', matchCount: 9, win: 9, rank: 2.6154},
        {id:4, position: 4, name: 'капитан паника', country: 'Казахстан', matchCount: 9, win: 0, rank: 2.9154}
      ],
      visWhyTur: false
    }
  },
  mounted(){
    
  }
}

</script>