<template>
  <div class="video" id="video">
    <div class="video-top">
      <img class="video-top__number" src="@/assets/img/blocks/02.svg" alt="01">
      <div class="video-top__txt">
        Видео <br />по игре
      </div>
    </div>
    <div class="video-catalog">
      <iframe v-for="(videoItem, index) in videoAll" class="video-catalog__item" width="1920" :height="index==0 ? '860' : '360'" :src="videoItem" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

    <div class="video-catalog__mob">
      <iframe class="video-catalog__item" width="335" height="181" :src="videoAll[videoMobSrc]" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <div class="video-catalog__arrows">
        <img src="@/assets/img/arrow-left.png" @click="changeVideo(true)" />
        <img src="@/assets/img/arrow-right.png" @click="changeVideo(true)" />
      </div>
    </div>
  </div>
</template>

<script>
import style from './style.scss';

export default {
  name: 'Video',
  props: ['videoAll'],
  data(){
    return {
      videoMobSrc: 0
    }
  },
  methods: {
    changeVideo(e){
      if(e){
        if(this.videoMobSrc < this.videoAll.length - 1){
          this.videoMobSrc++;
        } else {
          this.videoMobSrc = 0;
        }
        
      } else {
        if(this.videoMobSrc > 0){
          this.videoMobSrc--;
        } else {
          this.videoMobSrc = this.videoAll.length -1
        }
      }
    }
  },
  mounted(){
    
  }
}

</script>
