<template>
  <div class="form" id="Form">
    <div class="form-top">
      <img class="form-top__number" src="@/assets/img/blocks/03w.svg" alt="04">
      <div class="form-top__txt">
        Стать курьером <br />и начать играть
      </div>
    </div>
    <div class="form-bot">
      <div class="form-bot-left">        
        <img class="form-bot-left__img" src="@/assets/img/kurer.gif" />
        <img class="form-bot-left__img-mob" src="@/assets/img/kurer-mob.gif" />
      </div>
      <div class="form-bot-right">
        <div class="form-bot-right__title">
        Присоединяйтесь к команде курьеров Delivery Club и участвуйте в чемпионате <br class="br__desk" />Delivery Cup. <br /><br />Работа у нас – возможность быть на свежем воздухе там, где вам удобно. <br class="br__desk" />Становитесь пешим, вело и автокурьером, чтобы радовать людей вкусной едой и <br class="br__desk" />свежими продуктами. Совмещайте подработку с учёбой или основной работой, не <br class="br__desk" />забывая про любимые хобби!
              
        </div>
        <a href="https://delivery-club.work/?utm_source=delivery_cup&utm_medium=cpm&utm_campaign=pr_delivery_cup_15_04_2021" target="_blank" class="form-bot-right__btn">
          <span>
          Стать курьером
          </span>
        </a>
      </div> 
    </div>
  </div>
</template>

<script>
import style from './style.scss';

export default {
  name: 'Form',
  props: ['desk']
}

</script>