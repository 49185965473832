<template>
  <div class="turnament">
    <div class="turnament-top">
      <img class="turnament-top__number" src="@/assets/img/blocks/02.svg" alt="01">
      <div class="turnament-top__txt">
        Турнирная<br />таблица
      </div>
    </div>

    <div class="turnament-main">
      <div v-for="(item, index) in turnData" class="turnament-main-block" >
        <div class="turnament-main-block__contain" :class="item.ready && 'ready'">
          <div v-if="index == turnData.length-1" class="turnament-main-block__contain__final">В ФИНАЛЕ</div>
          <div class="turnament-main-block__date" :class="item.ready && 'ready'" v-html="item.date ? item.date : ''"> </div>
          <div class="turnament-main-block-comand" :class="item.ready && 'ready'" v-for="itemComand in item.comand">
          <div class="turnament-main-block-comand__txt">
              <span v-if="item.date" class="turnament-main-block-comand__txt__name">{{itemComand.name}}</span>
              <span v-if="item.date" class="turnament-main-block-comand__txt__win">{{itemComand.win}}</span>
              <div v-else class="turnament-main-block-comand__txt__img">
                <img src="@/assets/img/ask.svg" />
              </div>
          </div>
          </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import style from './style.scss'

export default {
  name: 'TurnaTable',
  data(){
    return{
      turnData: [
        {date: 'игра 1 / 28.02 14:30', ready: true, comand: [{name: 'РепульФорев', win: 5}, {name: 'Паладин', win: 2}]},
        {date: 'игра 1 / 29.02 14:30', ready: true, comand: [{name: 'Варк', win: 3}, {name: 'Револьф', win: 6}]},
        {date: 'игра 1 / 30.02 14:30', ready: true, comand: [{name: 'Сапорт', win: 6}, {name: 'Паладин', win: 3}]},
        {date: 'игра 1 / 31.02 14:30', ready: false, comand: [{name: 'Форев', win: 8}, {name: 'Барав', win: 8}]},
        {date: false, ready: false, comand: [{name: '?', win: 3}, {name: 'Паладин', win: 1}]},
        {date: false, ready: false, comand: [{name: '?', win: 1}, {name: 'Паладин', win: 0}]},
        {date: false, ready: false, comand: [{name: '?', win: 0}, {name: 'Паладин', win: 10}]}
      ]
    }
  }
}
</script>
